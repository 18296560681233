import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import useStep from "common/src/component/useStep";
import {
  MUTATION_REGISTER,
  // MUTATION_CREATE_LIMITS,
  // MUTATION_UPDATE_BANKTRANSFER,
} from "common/src/graphql/mutations";
import { useApolloClient } from "@apollo/client";
import { setToken, getToken } from "common/src/helpers/session";
import { toast } from "react-toastify";
import configData from "../config.json";
import RegisterStepVerify from "common/src/component/RegisterStepVerify";

//steps
import RegisterStepIdin from "common/src/component/RegisterStepIdin";
import RegisterStep1 from "common/src/component/RegisterStep1";
import RegisterStepIdent from "common/src/component/RegisterStepIdent";
import RegisterStep2 from "common/src/component/RegisterStep2";
import RegisterBSN from "common/src/component/RegisterBSN";
import RegisterStep2NL from "common/src/component/RegisterStep2NL";
import RegisterStep2SE from "common/src/component/RegisterStep2SE";
import RegisterStepLimitsNL from "common/src/component/RegisterStepLimitsNL";
import RegisterStepUK from "common/src/component/RegisterStepUK";
import RegisterStepGDPR from "common/src/component/RegisterStepGDPR";
import SuccessMessage from "common/src/component/SuccessMessage";

import Cookies from "js-cookie";

import useForm from "common/src/helpers/useForm";
import validate from "common/src/helpers/RegisterFormValidationRules";
import "../data/scss/components/register.scss";
import { PaymentServer } from "common/src/helpers/connectors";
import { useTranslation } from "react-i18next";
import {
  AccountContainer,
  PageContent,
  PageContentWrapper,
} from "common/src/styledComponents/AccountPage";
import { StyledMb } from "common/src/styledComponents/CommonStyledComponents";
import { SpanStep } from "common/src/styledComponents/StyledSignupLogin";
import { LOGIN_FLAG } from "common/src/helpers/constants";

const Register = ({
  serverSetup,
  pages,
  setSpinner,
  titles,
  setPxRegister,
  setPxRegisterStart,
  player,
  landing,
}) => {
  // const [registrationStep, setRegistrationStep] = useState("first");
  // const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const apolloClient = useApolloClient();
  const { t } = useTranslation("");

  useEffect(() => {
    // console.log(titles)
    document.title = titles["register"];
  }, [titles]);

  useEffect(() => {
    return () => {
      if (!getToken()) {
        setPxRegisterStart(false);
        setPxRegister(false);
      }
    };
  }, [setPxRegisterStart, setPxRegister]);

  const [notAllowed, setNotAllowed] = useState(false);
  const [stepVerify, setStepVerify] = useState(false);
  const [ibanError, setIbanError] = useState("");

  useEffect(() => {
    if (getToken()) {
      if (configData.skin !== "plc") {
        setNotAllowed(true);
        // console.log("not allowed");
      } else {
        //register verify
        if (Cookies.get("activated")) {
          Cookies.remove("activated");
          setStepVerify(true);
        } else {
          // console.log("plc no activation cookie, redirect");
          setNotAllowed(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    const url = "https://www.google.com/recaptcha/api.js";
    const queryString = "?render=" + configData.reCAPTCHA_site_key;
    script.src = url + queryString;
    script.async = true;
    // script.addEventListener("load", handleLoader);
    document.body.appendChild(script);
  }, []);

  const {
    handleChange,
    handlePhoneChange,
    handleDateChange,
    handleDateRangeChange,
    handleBlur,
    handleOnPhoneBlur,
    handleOnDateBlur,
    setTouchedOnRest,
    handleSubmit,
    formData,
    errors,
    touched,
    checkForEmptyFields
  } = useForm(
    configData.loginOnRegistration,
    configData.reCAPTCHA_site_key,
    "",
    "",
    validate,
    checkIBAN
  );

  const {
    email,
    password,
    currency,
    country,
    firstName,
    middleName,
    lastName,
    state,
    city,
    zipCode,
    address,
    phone,
    gender,
    title,
    dateOfBirth,
    //affiliateTrackingCode,
    language,
    addictionPolicy,
    legalCapacity,
    receiveNews,
    receiveSMS,
    abode,
    buildingNumber,
    buildingName,
    amlPolicy,
    gdprAccepted,
    receiveBonus,
    pep,
    identID,
    identBankID,
    geoIP,
    activate,
    birthPlace,
    inserts,
    BSN,
    // loginCountDate,
    // loginCountValue,
    timeLimitDate,
    timeLimitValue,
    depositDate,
    depositValue,
    maxBalansLimit,
    kycDocumentType,
    kycIDNumber,
    kycDocumentCity,
    documentValidFrom,
    documentValidTo,
    bankIBAN,
    bankAccountHolder,
  } = formData;
  const [complete, setComplete] = useState(null);

  // Check if bank id exist
  // async function checkBankID(event) {}

  // Check if phone exist
  // async function checkPhone(event) {}

  // Register player
  async function registerPlayer() {
    // console.log("No errors, submit callback called!");

    const affiliateTrackingCode = Cookies.get(
      "affCode" + configData.skin.toUpperCase()
    )
      ? Cookies.get("affCode" + configData.skin.toUpperCase())
      : "";
    //console.log(affiliateTrackingCode);
    try {
      const { loading, errors, data } = await apolloClient.mutate({
        mutation: MUTATION_REGISTER,
        variables: {
          password: password,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          email: email,
          state: state,
          city: city,
          country: country,
          zipCode: zipCode,
          address: address,
          phone: phone,
          gender: gender,
          title: title,
          dateOfBirth: dateOfBirth,
          affiliateTrackingCode: affiliateTrackingCode,
          language: language,
          addictionPolicy: addictionPolicy,
          legalCapacity: legalCapacity,
          receiveNews: receiveNews,
          receiveSMS: receiveSMS,
          abode: abode,
          buildingNumber: buildingNumber === "" ? 0 : parseInt(buildingNumber),
          buildingName: buildingName,
          amlPolicy: amlPolicy,
          currency: currency,
          gdprAccepted: gdprAccepted,
          receiveBonus: receiveBonus,
          pep: pep,
          identID: identID,
          identBankID: identBankID,
          geoIP: geoIP,
          birthPlace: birthPlace,
          inserts: inserts,
          BSN: BSN,
          activate: activate,
          kycDocumentType: kycDocumentType,
          kycIDNumber: kycIDNumber,
          kycDocumentCity: kycDocumentCity,
          documentValidFrom: documentValidFrom,
          documentValidTo: documentValidTo,
          accountHolder: bankAccountHolder,
          accountNumber: bankIBAN,
          limits: prepLimits(),
        },
      });

      if (loading) setSpinner(true);
      if (errors) {
        setSpinner(false);
        toast.error(errors[0].message, {position: "bottom-center"});
        if (errors[0].message === "error_player_self_excluded") {
          // tracking pixels - if error is self excluded player is still registered
          setPxRegister(true);
        }
        return "Error ...";
      } else {
        if (data.register.token !== null) {
          setSpinner(false);
          setComplete(`Token: ${data.register.token}`);
          if (data.register.token !== "") {
            setToken(data.register.token);
            localStorage.setItem(LOGIN_FLAG, true); //login flag says first me call after login
          }
          // tracking pixels
          setPxRegister(true);
          // setRegisterLimit(data.register.token);
          // setRegisterBankData(data.register.token);
        }
        return "Ok ...";
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  function prepLimits() {
    const typeDeposit = "deposit";
    // const typeLoginCount = 'logincount';
    const typeTimeLimit = "timelimit";
    const typeMaxBalance = "maxbalance_limit";

    //daily_timelimit

    const depositType = depositDate + "_" + typeDeposit;
    // const limitType = loginCountDate + '_' + typeLoginCount;
    const timeLimitType = timeLimitDate + "_" + typeTimeLimit;
    const maxBalansType = typeMaxBalance;

    let limits = [
      { initial_amount: parseFloat(depositValue * 100), type: depositType },
      {
        initial_amount: parseFloat(timeLimitValue * 3600),
        type: timeLimitType,
      },
      // { initial_amount: parseFloat(loginCountValue), type: limitType },
      { initial_amount: parseFloat(maxBalansLimit * 100), type: maxBalansType },
    ];
    // console.log("prepped limits: " + JSON.stringify(limits));
    return JSON.stringify(limits);
  }

  /* set limit */
  // async function setRegisterLimit(token) {
  //   const typeDeposit = 'deposit';
  //   const typeLoginCount = 'logincount';
  //   const typeMaxBalance = 'maxbalance_limit';
  //
  //   const depositType = depositDate + '_' + typeDeposit;
  //   const limitType = loginCountDate + '_' + typeLoginCount;
  //   const maxBalansType = typeMaxBalance;
  //
  //   let limits = [
  //     { initial_amount: parseFloat(depositValue * 100), type: depositType },
  //     { initial_amount: parseFloat(loginCountValue), type: limitType },
  //     { initial_amount: parseFloat(maxBalansLimit * 100), type: maxBalansType },
  //   ];
  //
  //   const { loading, errors, data } = await apolloClient.mutate({
  //     mutation: MUTATION_CREATE_LIMITS,
  //     headers: {
  //       authorization: `Bearer ${token}`,
  //       common: {
  //         'X-Http-Auth': token,
  //       },
  //     },
  //     variables: { limits: JSON.stringify(limits) },
  //     errorPolicy: 'all',
  //   });
  //   if (loading) return 'Loading ...';
  //   if (errors) {
  //     setSpinner(false);
  //     toast.error(errors[0].message, {
  //       position: 'bottom-center',
  //     });
  //     return false;
  //   }
  //   if (data) {
  //     setSpinner(false);
  //     return true;
  //   }
  // }

  /* set limit */
  // async function setRegisterBankData(token) {
  //   let params = {
  //     accountHolder: bankAccountHolder,
  //     accountNumber: bankIBAN,
  //     iBan: '',
  //     bic: '',
  //     sortCode: '',
  //     bankName: '',
  //     bankCountry: '',
  //     clearingNumber: '',
  //     bankBranch: '',
  //     bankIFSC: '',
  //     bankAddress: '',
  //   };
  //
  //   const { loading, errors, data } = await apolloClient.mutate({
  //     mutation: MUTATION_UPDATE_BANKTRANSFER,
  //     headers: {
  //       authorization: `Bearer ${token}`,
  //       common: {
  //         'X-Http-Auth': token,
  //       },
  //     },
  //     variables: params,
  //     errorPolicy: 'all',
  //   });
  //   if (loading) return 'Loading ...';
  //   if (errors) {
  //     setSpinner(false);
  //     toast.error(errors[0].message, {
  //       position: 'bottom-center',
  //     });
  //     return false;
  //   }
  //   if (data) {
  //     setSpinner(false);
  //     return true;
  //   }
  // }

  let steps = [];
  if (configData.skin === "plc") {
    steps = [
      { id: "idin" },
      { id: "secondNL" },
      { id: "bsnNL" },
      { id: "limitsNL" },
      { id: "stepGDPR" },
      { id: "success" },
      { id: "verify" },
    ];
    // if(stepVerify) {
    //   steps.push({ id: "verify" })
    // }
  } else {
    if (serverSetup && serverSetup.geoIP.isoCode.toLowerCase() === "se") {
      steps = [
        { id: "stepIdentLookup" },
        { id: "secondSE" },
        { id: "stepGDPR" },
        { id: "success" },
      ];
    } else {
      steps = [
        { id: "first" },
        { id: "stepIdent" },
        { id: "secondSE" },
        { id: "second" },
        { id: "stepUK" },
        { id: "stepGDPR" },
        { id: "success" },
      ];
    }
  }

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { go } = navigation;

  if (stepVerify && step.id !== "verify") {
    go("verify");
  }

  async function checkIBAN() {
    if (country !== "nl") {
      //if not nl, iban field is not mandatory so don't do check
      registerPlayer();
    } else {
      PaymentServer.post("/fhnd/v1/iban/check", {
        ip: serverSetup.geoIP.ipAddress,
        // name: "Gaëlle Vital de Weerdestein in de Waagh",
        name: firstName + " " + inserts + " " + lastName,
        IBAN: bankIBAN,
      })
        .then(() => {
          // console.log("success: " + JSON.stringify(res));
          registerPlayer();
        })
        .catch((error) => {
          // console.log("error: " + JSON.stringify(error.response.data));
          if (error.response.data[0] && error.response.data[1]) {
            toast.error(
              t(error.response.data[0]) + ": " + t(error.response.data[1])
            );
            setIbanError("err_iban_result");
          } else if (error.response.data[0]) {
            toast.error(t(error.response.data[0]));
            setIbanError("err_iban_result");
          } else {
            toast.error(t("err_iban_result"));
            setIbanError("err_iban_result");
          }
          setSpinner(false);
          go("secondNL");
        });
    }
  }
  // console.log("navigation: " + JSON.stringify(step));
  const { id } = step;

  const props = {
    handleChange,
    handlePhoneChange,
    handleDateChange,
    handleDateRangeChange,
    handleOnPhoneBlur,
    handleOnDateBlur,
    handleBlur,
    setTouchedOnRest,
    formData,
    navigation,
    errors,
    touched,
    complete,
    checkForEmptyFields
    //registerErrors,
  };

  function renderStep(id) {
    switch (id) {
      case "idin":
        return (
          <RegisterStepIdin
            {...props}
            setSpinner={setSpinner}
            skin={configData.skin}
          ></RegisterStepIdin>
        );
      case "first":
        return (
          <RegisterStep1
            {...props}
            setPxRegisterStart={setPxRegisterStart}
            skin={configData.skin}
            config={configData}
          ></RegisterStep1>
        );
      case "stepIdent":
        return (
          <RegisterStepIdent
            {...props}
            setSpinner={setSpinner}
            lookup={false}
          ></RegisterStepIdent>
        );
      case "stepIdentLookup":
        return (
          <RegisterStepIdent
            {...props}
            setSpinner={setSpinner}
            lookup={true}
          ></RegisterStepIdent>
        );
      case "second":
        return (
          <RegisterStep2 skin={configData.skin} {...props}></RegisterStep2>
        );
      case "secondNL":
        return (
          <RegisterStep2NL
            {...props}
            setSpinner={setSpinner}
            ibanError={ibanError}
          ></RegisterStep2NL>
        );
      case "secondSE":
        return (
          <RegisterStep2SE {...props} setSpinner={setSpinner}></RegisterStep2SE>
        );
      case "stepUK":
        return (
          <RegisterStepUK
            {...props}
            setSpinner={setSpinner}
            skin={configData.skin}
          ></RegisterStepUK>
        );
      case "bsnNL":
        return <RegisterBSN {...props}></RegisterBSN>;
      case "limitsNL":
        return <RegisterStepLimitsNL {...props}></RegisterStepLimitsNL>;
      case "stepGDPR":
        return (
          <RegisterStepGDPR
            {...props}
            pages={pages}
            setSpinner={setSpinner}
            skin={configData.skin}
          ></RegisterStepGDPR>
        );
      case "success":
        return <SuccessMessage pages={pages} {...props}></SuccessMessage>;
      case "verify":
        return (
          <RegisterStepVerify
            pages={pages}
            {...props}
            player={player}
            setSpinner={setSpinner}
          ></RegisterStepVerify>
        );
      default:
        return (
          <RegisterStep1 {...props} skin={configData.skin}></RegisterStep1>
        );
    }
  }

  // if (getToken()) return <Redirect to="index"></Redirect>;
  if (notAllowed) {
    return <Redirect to="index"></Redirect>;
  }
  // if (serverSetup.geoIP.isoCode.toLowerCase() === "se") {
  //   return <Redirect to="index"></Redirect>; //?
  // }
  // Render page
  let indexOfActiveStep = steps.indexOf(step);
  if (stepVerify) {
    indexOfActiveStep--;
  }

  let hideSEstep = country === "se" || currency === "sek";
  let hideUKstep = country === "gb";
  let stepCounter = 1;

  return (
    <PageContent land={landing} bg dep>
      <PageContentWrapper>
        <AccountContainer container>
          {step.id !== "success" && (
            <StyledMb>
              {steps.map(
                (st, index) =>
                  st.id !== "success" &&
                  (hideSEstep ? st : st.id !== "stepIdent") &&
                  (hideUKstep ? st : st.id !== "stepUK") && (
                    <SpanStep
                      key={step.id + "-" + index}
                      className={
                        index < indexOfActiveStep
                          ? "step previ"
                          : (st.id !== "success" && st.id === step.id) ||
                            stepVerify
                          ? "step active"
                          : "step"
                      }
                    >
                      {stepCounter++}
                    </SpanStep>
                  )
              )}
            </StyledMb>
          )}
          {!stepVerify && (
            <form
              //onSubmit={(event) => registerPlayer(event)}
              onSubmit={handleSubmit}
              className="text-left"
            >
              {renderStep(id)}

              {/** identID */}
              {/** identBankID */}
              {/** geoIP */}
              {/*
                <button type="button" onClick={(event) => checkBankID(event)}>
                  Check BankID
                </button>
               <button type="button" onClick={(event) => checkPhone(event)}>
                  Check Phone
                </button> 
              </div> */}
            </form>
          )}
          {stepVerify && renderStep(id)}
        </AccountContainer>
      </PageContentWrapper>
    </PageContent>
  );
};

export default withRouter(Register);
