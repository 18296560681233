import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { QUERY_CHECK_PHONE } from "common/src/graphql/queries";
import { MUTATION_UPDATE_PROFILE } from "common/src/graphql/mutations";
import { useApolloClient } from "@apollo/client";
import { withRouter } from "react-router";
import { ContentTop } from "common/src/component/ContentTop";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { isMobile, isMobileOnly } from "react-device-detect";
import { handleOrientationChange } from "common/src/helpers/isPortrait";
import {
  AccountCustomFormInline,
  AccountDescription,
  PageContent,
  PageContentWrapper,
  StyledUserName,
  MyProfileButton,
  AccountContainer,
  /* StyledFormRow, */
  StyledFormWrapper,
  StyledFormLastRow,
  InvalidFeedback,
  AccountCol,
} from "common/src/styledComponents/AccountPage";
import {
  StyledInput,
  StyledSelect,
} from "common/src/styledComponents/CommonStyledComponents";
import { StyledRow } from "common/src/styledComponents/StyledActiveBonus";
import { FooterButton } from "common/src/styledComponents/StyledFooter";
import { StyledMaterialIcon } from "common/src/styledComponents/StyledHeader";

const Profile = ({ player, pages, titles, setIsOpen, skin }) => {
  const apolloClient = useApolloClient();
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [editButton, setEditButton] = useState(true);
  const [expand, setExpand] = useState(false);
  const { t, i18n } = useTranslation("");
  const localizedFormat = `${
    i18n.language || window.localStorage.i18nextLng || "nl"
  }`;

  const handleExpend = (e) => {
    e.preventDefault();
    setExpand(!expand);
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  }, []);

  const {
    firstName,
    lastName,
    email,
    dateOfBirth,
    country,
    phone,
    receiveBonus,
    receiveNews,
    receiveSMS,
    /* doubleIP, */
    bonusBlocked,
  } = player;

  //console.log(player);
  const iniData = {
    phone: phone,
    news: receiveNews,
    sms: receiveSMS,
  };
  /*  if (!doubleIP) {
} */
  iniData.bonus = receiveBonus;

  const [updateData, setUpdateData] = useState(iniData);

  useEffect(() => {
    setUpdateData(iniData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  const [error, setError] = useState({});

  useEffect(() => {
    // console.log(titles)
    document.title = titles["my-profile"];
  }, [titles]);

  // Check if phone exist
  async function checkPhone(phone) {
    try {
      /* console.log('check phone:', phone); */
      const { errors } = await apolloClient.query({
        query: QUERY_CHECK_PHONE,
        variables: {
          phone: phone,
        },
      });
      //if (loading) return "Loading ...";
      if (errors === undefined) return false; //errors[0].message;
      return true;
    } catch (ex) {}
  }

  const handleBlur = (event) => {
    const { value } = event.target;
    let error = {};
    const phoneReg = /^\+?[0-9]+$/;
    if (value === "") {
      error.phone = "err_empty_phone";
    } else if (value.length < 5 || value.length > 15) {
      error.phone = "err_please_insert_phone";
    } else if (!phoneReg.test(value)) {
      error.phone = "err_not_number_phone";
    } else {
      iniData.phone !== value &&
        checkPhone(value).then((data) => {
          data
            ? (error.phone = "err_register_phone_exists")
            : delete error.phone;
        });
    }

    setError(error);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (value === "true" || value === "false") {
      value = JSON.parse(value);
    }
    setUpdateData({ ...updateData, [name]: value });
  };

  function getDiff(obj1, obj2) {
    var result = {};
    if (Object.is(obj1, obj2)) {
      return undefined;
    }
    if (!obj2 || typeof obj2 !== "object") {
      return obj2;
    }
    Object.keys(obj1 || {})
      .concat(Object.keys(obj2 || {}))
      .forEach((key) => {
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
          result[key] = obj2[key];
        }
        if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
          const value = getDiff(obj1[key], obj2[key]);
          if (value !== undefined) {
            result[key] = value;
          }
        }
      });
    return result;
  }

  async function updateProfile() {
    const diff = getDiff(iniData, updateData);
    if (editButton) {
      setEditButton(false);
    } else {
      setEditButton(true);
      if (Object.entries(diff).length !== 0) {
        const regEx = /^\+?[0-9]+$/;
        if (!regEx.test(updateData.phone)) {
          toast.error(error.phone, {
            position: "bottom-center",
          });
          return false;
        }
        const { loading, errors } = await apolloClient.mutate({
          mutation: MUTATION_UPDATE_PROFILE,
          variables: diff,
          errorPolicy: "all",
        });
        if (loading) return "Loading ...";
        if (errors) {
          toast.error(errors[0].message, {
            position: "bottom-center",
          });
          return false;
        }
        toast.success(t("update_profile_success"), {
          position: "bottom-center",
        });
        return true;
      }
    }
  }

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("orientationchange", handleOrientationChange);
      return () =>
        window.removeEventListener(
          "orientationchange",
          handleOrientationChange
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOrientationChange]);

  let mobileLandscape =
    isMobileOnly && !handleOrientationChange() ? true : false;

  return (
    <>
      <ContentTop
        serverSetup={serverSetup}
        showProfileMenu={!isMobileOnly}
        pages={pages}
        isMyAccount={"label_profile"}
        setIsOpen={setIsOpen}
        skin={skin}
      />
      <PageContent account="true" nobg="true">
        <PageContentWrapper>
          <AccountContainer>
            <AccountDescription expand={expand}>
              <h5>
                {t("label_myprofile_description")}
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker. */}
              </h5>
            </AccountDescription>
            <FooterButton onClick={handleExpend}>
              <StyledMaterialIcon as="span" icon={expand}></StyledMaterialIcon>
            </FooterButton>
            <AccountCustomFormInline>
              <StyledUserName>
                {firstName} {lastName}
              </StyledUserName>
              <MyProfileButton type="submit" onClick={() => updateProfile()}>
                {editButton ? t("label_edit") : t("label_send")}
                {/* {t(editButton ? 'label_edit' : 'label_send')} */}
              </MyProfileButton>
            </AccountCustomFormInline>
            <StyledRow>
              <AccountCol>
                <StyledFormWrapper spanborderbottom>
                  <label>{t("label_first_name")} </label>
                  <StyledInput
                    bonus
                    type="text"
                    name="firstName"
                    value={firstName}
                    disabled
                  />
                </StyledFormWrapper>
                <StyledFormWrapper spanborderbottom>
                  <label>{t("label_last_name")} </label>
                  <StyledInput
                    bonus
                    type="text"
                    name="lastName"
                    value={lastName}
                    disabled
                  />
                </StyledFormWrapper>
                <StyledFormWrapper spanborderbottom>
                  <label>{t("label_email")} </label>
                  <StyledInput
                    bonus
                    type="text"
                    name="userName"
                    value={email}
                    disabled
                  />
                </StyledFormWrapper>

                <StyledFormWrapper
                  mobileLandscape={mobileLandscape}
                  spanborderbottom
                >
                  <label>{t("label_date_of_birth")} </label>
                  <StyledInput
                    bonus
                    type="text"
                    name="dateOfBirth"
                    value={
                      dateOfBirth &&
                      new Date(dateOfBirth)
                        .toLocaleDateString(localizedFormat)
                        .replace(/-/g, "/")
                    }
                    disabled
                  />
                </StyledFormWrapper>
              </AccountCol>
              <AccountCol>
                <StyledFormWrapper
                  spanborderbottom
                  mobileLandscape={mobileLandscape}
                >
                  <label>{t("label_state")} </label>
                  <StyledInput
                    bonus
                    type="text"
                    name="country"
                    value={country}
                    disabled
                  />
                </StyledFormWrapper>
                <StyledFormWrapper
                  mobileLandscape={mobileLandscape}
                  spanborderbottom={editButton ? true : false}
                  editButton={!editButton}
                >
                  <label>{t("label_phone")} </label>
                  <StyledInput
                    validFormNumber={error.phone}
                    spanPadding={" pr-5"}
                    type="text"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    name="phone"
                    value={updateData.phone}
                    disabled={editButton}
                  />
                  {error.phone && (
                    <InvalidFeedback abc>{t(error.phone)}</InvalidFeedback>
                  )}
                </StyledFormWrapper>
                {/* <StyledFormLastRow editButton={editButton} doubleIP={doubleIP}>
                  <label>{t("label_bonus_opt_in")}</label>
                  <StyledSelect
                    formControllSpan
                    type="select"
                    name="bonus"
                    id="receiveBonus"
                    value={updateData.bonus}
                    onChange={(e) => handleChange(e)}
                    disabled={doubleIP || bonusBlocked ? true : editButton}
                  >
                    <option value="true">{t("label_yes")}</option>
                    <option value="false">{t("label_no")}</option>
                  </StyledSelect>
                </StyledFormLastRow> */}
                <StyledFormLastRow
                  editButton={editButton}
                  bonusBlocked={bonusBlocked}
                >
                  <label>{t("label_email_marketing")}</label>
                  <StyledSelect
                    formControllSpan
                    type="select"
                    name="news"
                    value={updateData.news}
                    onChange={(e) => handleChange(e)}
                    disabled={bonusBlocked ? true : editButton}
                  >
                    <option value="true">{t("label_yes")}</option>
                    <option value="false">{t("label_no")}</option>
                  </StyledSelect>
                </StyledFormLastRow>
                <StyledFormLastRow
                  editButton={editButton}
                  bonusBlocked={bonusBlocked}
                >
                  <label>{t("label_sms_marketing")}</label>
                  <StyledSelect
                    formControllSpan
                    name="sms"
                    type="select"
                    value={updateData.sms}
                    onChange={(e) => handleChange(e)}
                    disabled={bonusBlocked ? true : editButton}
                  >
                    <option value="true">{t("label_yes")}</option>
                    <option value="false">{t("label_no")}</option>
                  </StyledSelect>
                </StyledFormLastRow>
              </AccountCol>
              {/* <StyledFormRow>
                 {!doubleIP && (
                    <div className="custom-control custom-checkbox col-12 col-md-4 ">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        onChange={(e) => handleChange(e)}
                        id="receiveBonus"
                        name="bonus"
                        checked={updateData.bonus}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="receiveBonus"
                        style={{ lineHeight: 2 }}
                      >
                        {t('label_bonus_opt_in'), 'Bonus Included?'}{' '}
                      </label>
                    </div>
                  )}
              </StyledFormRow> */}
            </StyledRow>
          </AccountContainer>
        </PageContentWrapper>
      </PageContent>
    </>
  );
};

export default withRouter(Profile);
