import { AccountContainer } from "common/src/styledComponents/AccountPage.js";
import {
  LoaderCol,
  LoaderRow,
  PulseAnimation,
} from "common/src/styledComponents/StyledIndex.js";
import Logo from "../data/img/loader-logo.png";

export function Loader() {
  return (
    <AccountContainer vh>
      <LoaderRow>
        <LoaderCol>
          <PulseAnimation>
            <img src={Logo} alt="loader" />
          </PulseAnimation>
        </LoaderCol>
      </LoaderRow>
    </AccountContainer>
  );
}
