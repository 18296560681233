import { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import {
  SERVER_SETUP,
  SWE_LIMIT_DIALOG,
  PENDING_DEPOSIT,
} from "common/src/helpers/constants";
import { getToken } from "common/src/helpers/session";
import Modal from "react-modal";
import { BannedDialogWithMessage } from "common/src/modal/BannedDialogWithMessage";
import Slides from "common/src/component/Slider";
import { GameProvidersList } from "common/src/component/GameProvidersList";
import Categories from "common/src/component/Categories";
import PlayLive from "common/src/component/PlayLive";
import Lobby from "common/src/component/Lobby";
import PaymentMethods from "common/src/component/PaymentMethods";
import PnP from "common/src/component/PnP";
import config from "../config.json";
import {
  // GameCategoryHeader,
  // GameCategoryTitle,
  LobbyWrapper,
  StyledProviderLogo,
} from "common/src/styledComponents/StyledGame";
import {
  FilterABCWrapper,
  StyledFader,
  StyledFilters,
  StyledFiltersWrapper,
  StyledLoupeDiv,
  StyledPlayLiveWrapper,
} from "common/src/styledComponents/StyledIndex";
import { StyledTextCenter } from "common/src/styledComponents/AccountPage";
import useGetGameLobby from "common/src/services/useGetGameLobby";
import MainDescription from "common/src/component/MainDescription";
/* import { checkSWEDepositLimit } from "common/src/helpers/checkSWEDepositLimit"; */
// import { GameThemeProviders } from "common/src/component/GameThemeProviders";
import { isMobile, isMobileOnly } from "react-device-detect";
import { QUERY_GET_LIMITS } from "common/src/graphql/queries";
import { useApolloClient } from "@apollo/client";
import GamesLoader from "common/src/component/GamesLoader";

Modal.setAppElement("#root");

const Index = ({
  currentTheme,
  server,
  bannedDepositPage,
  hasWithdraw,
  player,
  riskyCountry,
  setSpinner,
  depositList,
  setPxRegisterStart,
  setPxDepositStart,
  setShowWithdrawAlertDialog,
  setSweLimitDialog,
  pages,
  titles,
  onToggleSearchShow,
  width,
  setIsOpen,
  indexPage,
  dashboardState,
  setDashboardState,
  selectedCategory,
  setSelectedCategory,
  setSearchResults,
  accountRoute,
  setProviderSearchFlag,
  providerSearchFlag,
}) => {
  const { t } = useTranslation("");
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [session] = useState(getToken());
  const [missingLimitsRedirect, setMissingLimitsRedirect] = useState(false);
  let location = useLocation();
  const apolloClient = useApolloClient();

  const currentTemplate = serverSetup.template;
  const [showBannedDialogWithMessage, setShowBannedDialogWithMessage] =
    useState(false);
  const [supportRedirect, setSupportRedirect] = useState(false);
  const [themeSerachTerm, setThemeSearchTerm] = useState("");
  const [scrollOnBack, setScrollOnBack] = useState(false);
  const [themeExists, setThemeExists] = useState(false);

  // const [showSetLimitsDialog, setShowSetLimitsDialog] = useState(false);
  const [show, setShow] = useState(false);

  let firstCategory =
    server &&
    server.gameCategories.length !== 0 &&
    server.gameCategories[0].name.toLowerCase().replace(/ /g, "-");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    window.onpopstate = () => {
      setScrollOnBack(true);
    };
    if (location.pathname === pages["index"]) {
      scrollToTop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDashboardState({
      categories: firstCategory ? firstCategory : null,
      games: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(titles)
    document.title = titles["index"];
  }, [titles]);

  let playerCountry =
    player && player.country !== undefined
      ? player.country
      : serverSetup.geoIP.isoCode.toLowerCase() !== ""
      ? serverSetup.geoIP.isoCode.toLowerCase()
      : config.defaultLang;

  let { lobby, orderedGameLobby } = useGetGameLobby(
    playerCountry,
    "",
    config.skin
  );

  useEffect(() => {
    lobby !== null &&
      orderedGameLobby !== null &&
      setDashboardState({
        categories: lobby[0] && lobby[0]["categoryName"],
        games: config.skin !== "plc" ? orderedGameLobby.lobby : lobby,
      });
    if (
      config.skin === "plc" ||
      config.skin === "pub" ||
      config.skin === "cac"
    ) {
      setSelectedCategory("popular-games");
    } else {
      setSelectedCategory(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lobby, orderedGameLobby]);

  useEffect(() => {
    if (config.skin === "rac" && session && bannedDepositPage) {
      if (!riskyCountry) {
        sessionStorage.setItem("user_banned_from_page", true);
      } else {
        sessionStorage.setItem("user_from_risky_country", true);
      }
      setSupportRedirect(true);
    }
  }, [session, bannedDepositPage, riskyCountry, setSweLimitDialog]);

  useEffect(() => {
    if (session && localStorage.getItem(SWE_LIMIT_DIALOG)) {
      setSweLimitDialog(true);
    }

    // if (
    //   config.skin === "rac" &&
    //   session &&
    //   hasWithdraw &&
    //   player.country !== "fi" &&
    //   player.country !== "se"
    // ) {
    //   setShowWithdrawAlertDialog(true);
    // }
  }, [
    session,
    // hasWithdraw,
    setSweLimitDialog,
    // setShowWithdrawAlertDialog,
    // player.country,
  ]);

  useEffect(() => {
    if (localStorage.getItem(PENDING_DEPOSIT)) {
      setShowBannedDialogWithMessage(true);
      localStorage.removeItem(PENDING_DEPOSIT);
    }
  }, []);

  useEffect(() => {
    if (scrollOnBack) {
      document.getElementById("scroll-to-category-onlogin")
        ? document.getElementById("scroll-to-category-onlogin").scrollIntoView()
        : scrollToTop();
    }
  }, [scrollOnBack]);

  /* useEffect(() => {
    if (
      sessionStorage.getItem("scrollToLobby") &&
      location.pathname === pages["index"]
    ) {
      document.getElementById("scroll-to-category-onlogin").scrollIntoView();
      sessionStorage.removeItem("scrollToLobby");
    }
  }, []); */

  function checkSWEDepositLimit() {
    // console.log("check is set limit for swe");
    let hasTimelimitDaily = false;
    let hasTimelimitWeekly = false;
    let hasTimelimitMonthly = false;
    let hasDepositLimit = false;
    getLimits().then((limits) => {
      limits &&
        limits.forEach((limit) => {
          if (limit.limit === "deposit") {
            hasDepositLimit = true;
          }
          if (
            limit.limitType === "daily_timelimit" &&
            limit.initialAmount > 0
          ) {
            hasTimelimitDaily = true;
          }
          if (
            limit.limitType === "monthly_timelimit" &&
            limit.initialAmount > 0
          ) {
            hasTimelimitMonthly = true;
          }
          if (
            limit.limitType === "weekly_timelimit" &&
            limit.initialAmount > 0
          ) {
            hasTimelimitWeekly = true;
          }
          // console.log(hasDepositLimit + " " + hasTimelimitDaily + " " + hasTimelimitMonthly + " " + hasTimelimitWeekly)
        });
      if (
        !hasDepositLimit /*||
        !hasTimelimitDaily ||
        !hasTimelimitWeekly ||
        !hasTimelimitMonthly */
      ) {
        // console.log("should redirect!");
        localStorage.setItem("sweSetDepositLimit", "show");
        // window.location = `${baseUrl}/my-limits`;
        setMissingLimitsRedirect(true);
      }
    });
  }

  async function getLimits() {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_LIMITS,
    });
    if (loading) return <h3>Loading ...</h3>;
    if (errors) return false;
    return data.getLimits.limits;
  }

  if (config.skin === "rac" && session && player.country === "se") {
    checkSWEDepositLimit();
  }

  const onCategoryClick = async (category) => {
    //console.log(category);
    setSelectedCategory(category.replace(/ /g, "-"));
  };

  const toggleShow = () => {
    setShow(!show);
  };

  const backToAllProviders = () => {
    if (themeSerachTerm && themeSerachTerm.length !== "") {
      setThemeSearchTerm("");
    }

    setProviderSearchFlag(false);
    setSelectedCategory(null);
    setDashboardState({
      categories: firstCategory,
      games: orderedGameLobby.lobby,
    });
  };

  if (supportRedirect) {
    return <Redirect to={pages["support"]}></Redirect>;
  }

  if (missingLimitsRedirect) {
    return <Redirect to={pages["my-limits"]}></Redirect>;
  }

  const { games } = dashboardState;
  //console.log('salje ' + JSON.stringify(games));
  return (
    <>
      {config.skin !== "rac" && (
        <>
          {/* Slider */}
          {indexPage && (
            <Slides
              currentLanguage={serverSetup.currentLanguage}
              currentTheme={currentTheme}
              banners={serverSetup.banners}
              player={player}
              server={server}
              setIsOpen={setIsOpen}
              skin={config.skin}
              landingImagePath={config.landingImagePath}
              defaultLang={config.defaultLang}
              defaultCurrency={config.defaultCurrency}
            />
          )}
          {/* Skin Description Div */}
          <MainDescription pages={pages} />
          <div id="scroll-to-category-onlogin"></div>
          {/* Game menu */}
          <StyledFiltersWrapper>
            <FilterABCWrapper>
              {currentTemplate === "fourth" || currentTemplate === "" ? (
                <StyledFilters>
                  <Categories
                    onClick={onCategoryClick}
                    selected={selectedCategory}
                    onToggleShow={() => toggleShow()}
                    serverSetup={serverSetup}
                    gameLobby={games}
                    backToAllProviders={backToAllProviders}
                    skin={config.skin}
                    setThemeSearchTerm={setThemeSearchTerm}
                    themeSerachTerm={themeSerachTerm}
                    providerSearchFlag={providerSearchFlag}
                    setProviderSearchFlag={setProviderSearchFlag}
                  />
                  {!isMobileOnly && (
                    <StyledLoupeDiv onClick={onToggleSearchShow} />
                  )}
                </StyledFilters>
              ) : (
                <ul>
                  <Categories serverSetup={serverSetup} gameLobby={games} />
                </ul>
              )}
            </FilterABCWrapper>
          </StyledFiltersWrapper>
          {/* Lobby */}
          {games === null ? (
            <LobbyWrapper gameLoader>
              <GamesLoader skin={config.skin} />
            </LobbyWrapper>
          ) : (
            <LobbyWrapper>
              <StyledTextCenter>
                <Lobby
                  gamesForCategory={config.skin === "plc" ? true : false}
                  gameLobby={games}
                  selectedCategory={selectedCategory}
                  width={width}
                  skin={config.skin}
                  gameImagePath={config.gameImagePath}
                  config={config}
                  country={playerCountry}
                  mobGamesInLobby={
                    selectedCategory === null &&
                    isMobile &&
                    !providerSearchFlag &&
                    (config.skin === "abc" || config.skin === "nbc")
                      ? true
                      : false
                  }
                  themes={true}
                  setThemeSearchTerm={setThemeSearchTerm}
                  themeSerachTerm={themeSerachTerm}
                  setSearchResults={setSearchResults}
                  accountRoute={accountRoute}
                  setSpinner={setSpinner}
                  setThemeExists={setThemeExists}
                  setSelectedCategory={setSelectedCategory}
                />
                {/*{themeExists && (*/}
                {/*  <GameCategoryHeader provider gameTheme>*/}
                {/*    <GameCategoryTitle category>*/}
                {/*      {t("label_chose_theme")}*/}
                {/*    </GameCategoryTitle>*/}
                {/*  </GameCategoryHeader>*/}
                {/*)}*/}
                {/*<GameThemeProviders*/}
                {/*  setThemeSearchTerm={setThemeSearchTerm}*/}
                {/*  themeSerachTerm={themeSerachTerm}*/}
                {/*  setSearchResults={setSearchResults}*/}
                {/*  skin={config}*/}
                {/*  accountRoute={accountRoute}*/}
                {/*  serverSetup={serverSetup}*/}
                {/*  setSpinner={setSpinner}*/}
                {/*  setSelectedCategory={setSelectedCategory}*/}
                {/*  setThemeExists={setThemeExists}*/}
                {/*/>*/}
              </StyledTextCenter>
            </LobbyWrapper>
          )}
          {/* Game provider list */}
          {config.skin === "plc" && (
            <StyledProviderLogo>
              <GameProvidersList
                setSearchResults={setSearchResults}
                skin={config.skin}
              />
            </StyledProviderLogo>
          )}
          {/* Play live section */}
          {config.skin === "plc" && (
            <StyledPlayLiveWrapper>
              <PlayLive pages={pages} />
            </StyledPlayLiveWrapper>
          )}
          {/* Payments List */}
          {config.skin === "plc" && (
            <StyledFader>
              <PaymentMethods
                currentTheme={currentTheme}
                pages={pages}
                serverSetup={serverSetup}
                currentLanguage={serverSetup.currentLanguage}
                skin={config.skin}
                paymentsImagePath={config.paymentsImagePath}
              />
            </StyledFader>
          )}
        </>
      )}
      {config.skin === "rac" && (
        <PnP
          server={server}
          player={player}
          pages={pages}
          currentTheme={currentTheme}
          depositList={depositList}
          setSpinner={setSpinner}
          setPxRegisterStart={setPxRegisterStart}
          setPxDepositStart={setPxDepositStart}
          skin={config.skin}
          paymentsImagePath={config.paymentsImagePath}
        />
      )}
      <BannedDialogWithMessage
        showModal={showBannedDialogWithMessage}
        handleProceed={() => setShowBannedDialogWithMessage(false)}
        message={t("label_pnp_pending_deposit")}
        skin={config.skin}
      />
    </>
  );
};

export default withRouter(Index);
