import { Suspense, useEffect } from "react";
import Routes from "./routes";
//import "./App.sass";
import "./data/scss/main.scss";
import ReactGA from "react-ga";
import configData from "./config.json";
import { useLocation } from "react-router-dom";
/* import { Loader } from "./helpers/loader"; */
import GlobalStyle from "./components/GlobalStyle";
import SpinnerLoaderNBC from "./components/SpinnerLoaderNBC";
/* import withClearCache from "./ClearCache"; */

if (configData.gaConfig !== "") {
  ReactGA.initialize(configData.gaConfig);
}

export default function App() {
  let location = useLocation();
  /* const ClearCacheComponent = withClearCache(Routes); */

  useEffect(() => {
    //google analytics section
    if (configData.gaConfig !== "") {
      // console.log("location: " + location.pathname);
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  return (
    <Suspense fallback={<SpinnerLoaderNBC />}>
      <GlobalStyle />
      <Routes />
    </Suspense>
  );
}
