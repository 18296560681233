import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html {
    overscroll-behavior: none;
    }
    body {
    margin: 0;
    background: var(--bgColorABC);
    background-color: var(--bgColorABC);
    overflow-x: hidden !important;
    overflow: hidden;
/*     transition: background-color 0.5s ease; */
    }
    body,
    tbody {
    &::-webkit-scrollbar {
        width: 8px;
    }
    }
    // Mozilla font size fix

    /* @-moz-document url-prefix() {
    html {
        font-size: 100% !important;
    }
    } */
    body,
    tbody,
    html {
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scrollbar-color: #afafaf #000000;
    }

    body,
    tbody {
    // fix to overlay scrollbar across page
    overflow: overlay;

    }



    body,
    tbody {
    &::-webkit-scrollbar-thumb {
        background-color: #592e76;
        border-radius: 3px;
        border: 0px solid var(--scrollbarBG);
    }
    }

    img {
    opacity: 1;
    /* transition: opacity 0.3s; */
    }

    img[data-srcset] {
    opacity: 0;
    }

    [srcset] {
    opacity: 1;
    /* transition: opacity 0.3s; */
    }

    [data-srcset] {
    opacity: 0;
    }

    #root {
    margin: 0;
    //background: var(--bgcolor);
    /* transition: all 0.2s linear; */
    overflow-x: hidden !important;
    }

    main {
    /* will-change: transform; */
    overflow-x: hidden !important;
    overflow-y: auto;
    }

    a {
    text-decoration: none;
    color: var(--tabletext);
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    }

    a:not(.btn):hover {
    text-decoration: none;
    color: #a18bac;
    cursor: pointer !important;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    }

    a:focus,
    button:focus,
    input:focus,
    textarea:focus {
    outline: none;
    }

    ::selection {
    color: white;
    background: var(--switchfill);
    }
    ::-moz-selection {
    color: white;
    background: var(--switchfill);
    }

    .hidden {
    display: none;
    }

    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
    * {
        animation-duration: 0.001ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.001ms !important;
    }
    }

    #flagz > img,
    #modez,
    #depz,
    #contz,
    #menu-a > a,
    .menu-main,
    #continuesvg:hover,
    #depositsvg:hover {
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    z-index: 9991;
    cursor: pointer !important;
    }

    .success-message {
    color: var(--welcomBonusFont);
    p {
        color: var(--smallHeaderABC);
    }
    }

    .form-group.required label:after {
    content: '*';
    }

    .hide{
        display: none;
    }
    .mob {
    position: fixed;
    z-index: 1030;
    height: 100%;
    width:100%;
  }
  .overflow-y-body{
    overflow-y: hidden !important;
  }
  /*------------ THEMING ------------- */

  //for safari 15
  /* @media (prefers-color-scheme: light) {
        :root {
            --theme-color: #fff;
        }
    }
    @media (prefers-color-scheme: dark) {
        :root {
            --theme-color: #0a2142;
        }
    } */

    /*---- React carousel arrow position ---- */
    .react-multiple-carousel__arrow--left {
        left: calc(0% + 1px);
    }
    .react-multiple-carousel__arrow--right {
        right: calc(0% + 1px);
    }
    #scroll-to-category-sportsbook, #scroll-to-category-onlogin{
        scroll-margin-top: 90px;
    }
    #scroll-to-category{
        scroll-margin-top: 20px;
    }
    .react-multi-carousel-list{
        padding: 1rem 0;
    }


    :root {
    --acolor: #fff;
    --bgcolor: #1f0529;
    --headerbg: #481a4b;
    --lobbybg: #350f46;
    --footerbg: #181e52;
    --switchfill: #977ea2;
    --pcolor: #0e1c81;
    --brandcolor: #fff
    --paginationHoverColor: #a18bac;
    --smalltext: #2c5194;
    --menuwidth: calc(100vh / 2);
    --footerp: #2c5194;
    --footerh: #977ea2;
    --menuhover: #fcf1f1;
    --scrollbarBG: #0e0b26;
    --thumbBG: #a18bac;
    --menubg: #ebbd00;
    --mainmenu: black;
    --submenu: black;
    --carouseltext: #06005e;
    --showall:#fff;
    --tabletext: #fff;
    --inputtext: #fff;
    --inputphcolor: #0e1c81;
    --selectbg: #350f46;
    --cookiesbg: #70328e;
    --gamebg: #360f48d9;
    --labelcolor: #210091;
    --labelcolordark: #4391ff;
    --labelcolordarkNBC: #fff;
    --bordercolor: rgba(36, 0, 144, 0.1);
    --newsText: #977ea2;
    --headerbtn: #fff;
    --icon: #fff;
    --playLive: #e1f4f3;
    --icon-bulb: #ae0005;
    --footerColor: #1f0529;
    --footerBorder: #dadde624;
    --footerTextColor:  #e2e2e2;
    --footerFaderBottom: #111747;
    --stepsColor: #d9efed;
    --stepsActivColor: #fff;
    --bubletop: #e1f4f3;
    --profileMenu: #3f1646;
    --sideMenu: #12061a;
    --progressBar: #eff3f5;
    --buttonFour: #1b0072;
    --profileBtn: #fff;
    --profileBtnHover: #70328e;
    --depositBorder: rgba(84, 0, 255, 0.045);
    --customInputColor: #350e46;
    --BanerBackgroundColor: #e1f4f3;
    --timeLeft: #a18bac;
    --disabledInputColor: #EFF3F5;
    --registerCalendarColor: #757575;
    --gameSearch: #fff;
    --cookieReverseColor: #fff;
    --fontHoverColorABC: #a18bac;
    --joinCasinoABC : #350f46;
    --welcomBonusFont : #fff;
    --bgColorABC : #1f0529;
    --bgHederABC : #fff;
    --borderABC : #dadde624;
    --copyrightABC : #fff;
    --profileBacgroundABC: #EAEBEF;
    --balanceABC: #0554b3;
    --profileHeaderBgABC: linear-gradient(0deg, rgba(1,20,60,1) 0%, rgba(233,233,233,1) 0%, rgba(1,20,60,0) 0.9%);
    --aboutH2Color: #977ea2;
    --aboutHeaderColor: #481a4b;
    --paymentHeaderColor: #ffffff52;
    --accountTextShadow:  /* 2px 2px 2px #e6e9ef */;
    --profileCashbackBgABC : linear-gradient(0deg, rgba(1,20,60,1) 0%, rgba(233,233,233,1) 0%, rgba(1,20,60,0) 0.8%);
    --smallHeaderABC: #fff;
    --inputbordercolorABC: #EBECEE;
    --tabletextHoverABC: #7d849a47;
    --inputbgABC: #350f46;
    --mainDescriptionABC: #350f46;
    --categoriesBgLinearABC: linear-gradient(0deg, rgb(53 15 70) 90%, rgb(112 50 142) 150%);
    --iconbulbABC: #1f0529d9;
    --accontFadeCollor: #230530;
    --landingBgColor: #350f46;
    --paymentsBg: #fff;
    --navProfileIconColor: #e007ae;
    --profileBubble:#70328e;
    --mobileNavBg: #1f0529;
    --mobileNavActiveBg: #120217f0;
    --navIconColor:#70328e;
    --subTextColorNav: #6998E0;
    --mobileNavBorder: #cecece;
    --navBg: linear-gradient(0deg, rgba(255,255,255,1) 76%, rgba(250,250,250,1) 100%);
    --cashbackBorderColor: #dadde624;
    --maintenenceBg: #481a4b;
    --tournamentsBorder: #684b74;
    --tournamentDescriptionColor: #fff;
    --tournamentPastBg: #431953;
    --torunamentPastCardBg:#360F46;
    --tournamentMoneyBag: #fff;
    --tournamentSelect: #70328E;
    --inGameDepositButtonBg: #70328e;
    --newsTextColor: #fff;
    --newsHeaderColor: #fff;


    }

    [data-theme='dark'] {
    --acolor: white;
    --bgcolor: #0a2142;
    --bgColorABC : #01143C;
    --headerbg: #000016;
    --lobbybg: #0a2142;
    --footerbg: #181e52;
    --brandcolor: #fff;
    --paginationHoverColor: #000000;
    --switchfill: #70328e;
    --pcolor: #858585;
    --footerp: #819ad7;
    --footerh: white;
    --menuhover: #1f355e;
    --scrollbarBG: black;
    --thumbBG: #7092e9;
    --menubg: #070911;
    --mainmenu: white;
    --submenu: #62789d;
    --carouseltext: #4d79ff;
    --showall: #fff;
    --tabletext: #fff;
    --inputtext: #fff;
    --inputphcolor: #fff;
    --selectbg: #0c101a;
    --cookiesbg: #fff;
    --gamebg: #360f48d9;
    --labelcolor: #fff;
    --labelcolordark: #4391ff;
    --bordercolor: rgba(255, 255, 255, 0.1);
    --newsText: #fff;
    --headerbtn: #fff;
    --icon: #fff;
    --playLive: #1a2e4e;
    --icon-bulb: #fff;
    --footerColor: #031836;
    --footerBorder: #ffffff80;
    --footerFaderBottom: #000e23;
    --stepsColor: #50505f;
    --stepsActivColor: #000016;
    --bubletop: #000016;
    --profileMenu: #1a2e4e;
    --sideMenu: #000015;
    --progressBar: #162f4f;
    --buttonFour: #212529;
    --profileBtn: #5c80b3;
    --profileBtnHover: #fff;
    //--depositBorder: rgba(77,121,255,.1);
    --depositBorder: rgba(255, 255, 255, 0.15);
    --customInputColor: #172e4e;
    --BanerBackgroundColor: #000016;
    --timeLeft: #7a8595;
    --disabledInputColor: #1a1a2d;
    --registerCalendarColor: white;
    --gameSearch: #fff;
    --footerTextColor: #D7DBDF;
    --cookieReverseColor: #a18bac;
    --fontHoverColorABC: #fff;
    --joinCasinoABC :  #0554B3;
    --welcomBonusFont :  #ffffff;
    --welcomBonusFont :  #ffffff;
    --bgHederABC : #01143C;
    --borderABC : #0e214b;
    --copyrightABC : #fff;
    --profileBacgroundABC: #0554b3;
    --profileHeaderBgABC: linear-gradient(
    0deg,
    rgba(1, 20, 60, 1) 0%,
    rgba(43, 68, 121, 1) 0%,
    rgba(1, 20, 60, 0) 3%
  );
    --balanceABC: #fff;
    --aboutH2Color: #ffffff;
    --aboutHeaderColor: #030d31;
    --paymentHeaderColor: #030d31;
    --accountTextShadow:  2px 2px 2px #0A1849;
    --profileCashbackBgABC : linear-gradient(
    0deg,
    rgba(1, 20, 60, 1) 0%,
    rgba(43, 68, 121, 1) 0%,
    rgba(1, 20, 60, 0) 1.5%
    );
    --smallHeaderABC: #0554B3;
    --inputbordercolorABC: #070A29;
    --tabletextHoverABC: #232337;
    --inputbgABC: #1a1a2d;
    --mainDescriptionABC: #030512;
    --categoriesBgLinearABC: linear-gradient(0deg, rgba(3,5,18,1) 86%, rgba(7,21,74,1) 120%);
    --iconbulbABC: #1f355e;
    --accontFadeCollor: #230530;
    --landingBgColor: #041332;
    --paymentsBg: #010522;
    --navProfileIconColor: #0554B3;
    --profileBubble: #206BC8;
    --mobileNavBg: #000e2bde;
    --mobileNavActiveBg: #01143c;
    --navIconColor: #206bc8;
    --subTextColorNav: #fff;
    --mobileNavBorder: #0d1f47;
    --navBg: linear-gradient(0deg, rgba(1,20,60,1) 76%, rgba(2,26,77,1) 100%);
    --cashbackBorderColor: #dadde624;
    --maintenenceBg: #481a4b;
    --tournamentsBorder: #684b74;
    --tournamentDescriptionColor: #fff;
    --tournamentPastBg: #431953;
    --torunamentPastCardBg:#360F46;
    --tournamentMoneyBag: #fff;
    --tournamentSelect: #70328E;
    --inGameDepositButtonBg: #70328e;
    --newsTextColor: #fff;
    --newsHeaderColor: #fff;
    }


    .icon {
        cursor: pointer;
        width: 42px;
        height: 20px;
        fill: var(--tabletext);
        padding: 0px 10px;
        margin: 0px 10px;
        &.dark {
            fill: #fff;
        }
        @media(max-width: 768px){
            margin-left: 0px;
        }
    }
    .invalid-feedback {
        margin-top: 0;
    }



    //------------ Input -----------------

    .react-tel-input .form-control {
        width: 100% !important;
        background-color: transparent;
        border: none !important;
        background-color: var(--inputbgABC) !important;
        color: var(--tabletext) !important;
        border-radius: 0.5rem;
        padding: 0 !important;
        font-size: 0.875rem;
        &:focus,
        &.open {
            color: var(--tabletext);
            background-color: transparent;
            border-color: var(--brandcolor);
            outline: 0;
            box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
        }
        }
        .react-tel-input {
        .flag-dropdown {
            height: calc(1.5em + 1.5rem + 3px);
        }
        input:disabled {
            background-color: var(--bgColorABC) !important;
            border: 1px solid var(--inputbgABC) !important;
        }
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    border: 1px solid inherit;
    -webkit-text-fill-color: #350e46;
    -webkit-box-shadow: 0 0 0px 1000px #fffcc8 inset;
    box-shadow: 0 0 0px 1000px #fffcc8 inset;
    }
    input[type="text"]:disabled {
        color: #350e46;
        background-color: #fffcc8;
    }
    input[type="text"]:read-only {
        /* color: var(--tabletext); */
        // color: #0554b3;
        /* background-color: inherit; */
    }

    .lobby-loader {
        width: 100%;
        height: 100%;
    }

    .headersvgclass.game {
    fill: var(--switchfill);
    }

    /* .header {
    width: calc(100% - 330px) !important;
    }
    */


    // ---------- Typography -----------

    a,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    label,
    .button,
    body {
    font-family: 'Catamaran', Fallback, sans-serif;
    }
    h3,
    h4,
    h5 {
    color: var(--switchfill);
    }
    h6 {
    font-size: 14px;
    font-weight: 500;
    color: var(--carouseltext);
    }
    h2 {
    font-weight: 500;
    color: #0554b3;
    }
    @media (max-width: 770px) {
    h6 {
        font-size: 12px;
    }
    }

    h3 {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    white-space: pre-line;
    text-align: center;
    }
    p {
    display: inline;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    white-space: pre-line;
    text-align: left;
    color: var(--pcolor);
    }

    @media (min-width: 300px) {
    h3 {
       /*  font-size: 12px;
        line-height: 14px; */
        color: var(--aboutH2Color);
    }
    p {
        font-size: 15px;
        line-height: 21px;
        text-align: left;
    }
    }
    @media (min-width: 600px) {
    h3 {
        font-size: 14px;
        line-height: 16px;
    }
    p {
        font-size: 16px;
        line-height: 23px;
        text-align: justify;
    }
    }
    @media (min-width: 800px) {
    p {
        font-size: 16px;
        line-height: 24px;
    }
    }
    @media (min-width: 1000px) {
    h3 {
        font-size: 17px;
        line-height: 26px;
    }
    p {
        font-size: 16px;
        line-height: 22px;
    }
    }
    @media (min-width: 1200px) {
    p {
        font-size: 16px;
        line-height: 28px;
    }
    }

   ${
     "" /*  @font-face {
    font-family: 'Catamaran';
    font-weight: 500;
    font-display: swap;
    src: url('../assets/fonts/Catamaran-Light.woff') format('woff');
    }

    @font-face {
    font-family: 'Catamaran';
    font-weight: 550;
    font-display: swap;
    src: url('../assets/fonts/Catamaran-Regular.woff') format('woff');
    }

    @font-face {
    font-family: 'Catamaran';
    font-weight: 700;
    font-display: swap;
    src: url('../assets/fonts/Catamaran-SemiBold.woff') format('woff');
    }

    @font-face {
    font-family: 'Catamaran';
    font-weight: 800;
    font-display: swap;
    src: url('../assets/fonts/Catamaran-ExtraBold.woff') format('woff');
    }

    @font-face {
    font-family: 'Catamaran';
    font-weight: 900;
    font-display: swap;
    src: url('../assets/fonts/Catamaran-Black.woff') format('woff');
    } */
   }


    // ------------ FORM ---------------
    .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

    // Because we use flex, the initial sizing of checkboxes is collapsed and
    // doesn't occupy the full-width (which is what we want for xs grid tier),
    // so we force that here.
    .form-check {
        width: 100%;
    }

    // Kick in the inline
    @media (min-width: 576px) {
        label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        }

        // Inline-block all the things for "inline"
        .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
        }

        // Allow folks to *not* use 
        .form-control {
        display: inline-block;
        width: auto; // Prevent labels from stacking above inputs in 
        vertical-align: middle;
        }
        
        // Make static controls behave like regular ones
        .form-control-plaintext {
        display: inline-block;
        }

        .input-group,
        .custom-select {
        width: auto;
        }

        // Remove default margin on radios/checkboxes that were used for stacking, and
        // then undo the floating of radios and checkboxes to match.
        .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
        }
        .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: $form-check-input-margin-x;
        margin-left: 0;
        }

        .custom-control {
        align-items: center;
        justify-content: center;
        }
        .custom-control-label {
        margin-bottom: 0;
        }
    }
    }
        .bnt-logout {
            display:none;
        }

        .bnt-logout-profile {
            display:none;
        }


   
    // ----------- Nav Links ---------------

    .menu-main {
        font-weight: 600 !important;
        font-size: 24px !important;
        color: var(--mainmenu);
    }
    @media (max-height: 900px) {
        font-size: 20px !important;
    }
    @media (max-height: 600px) {
    .menu-main {
        font-size: 16px !important;
    }

    // --------- Active Bonus Info --------
    .bonus-info {
        button {
            font-size: 2rem !important;
        }
    }
}

`;
export default GlobalStyle;
