import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "common/src/sw/serviceWorker";
import { BrowserRouter } from "react-router-dom";
import {
  ApolloProvider,
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { persistCache } from "apollo3-cache-persist";
import {
  AUTH_TOKEN,
  REFRESH_APP,
  REFRESH_APP_POPUP,
} from "common/src/helpers/constants";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";
import configData from "./config.json";
import { isIOS, isMobile, isMobileSafari, isTablet } from "react-device-detect";
import ErrorBoundary from "./ErrorBoundary";
import PWAPrompt from "react-ios-pwa-prompt";
// import { WebSocketLink } from 'apollo-link-ws';
// import { getMainDefinition } from 'apollo-utilities';
// import { split } from 'apollo-link';
// import { Loader } from '../src/helpers/loader';
/* import ComingSoon from "./pages/ComingSoon"; */
/* import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'; */

/* const baseUrl = `/${i18n.language || window.localStorage.i18nextLng || "en"}`; */
//Module for translate
//import "./i18n.js";

// Create HTTP link
const httpLink = new HttpLink({
  uri: configData.graphql,
});

// Create HTTP authentication link
const authLink = setContext((_, { headers }) => {
  // Get authentication token from localStorage
  const authentication = localStorage.getItem(AUTH_TOKEN);

  // Return the header to context so http link can read them
  return {
    headers: {
      ...headers,
      "X-Sender": configData.skin,
      "X-Device": isMobile || isTablet ? "mobile" : "desktop",
      Authentication: authentication ? `Bearer ${authentication}` : "",
    },
  };
});
const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  //console.log(networkError)
  //console.log(graphQLErrors)
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
  //window.location = baseUrl + `/logout`;
});

// Use concat to add RetryLink between HttpLink and Apollo client
const retry = new RetryLink({
  attempts: {
    attempts: { max: 10, retryIf: (error, _operation) => !!error },
    delay: {
      initial: 5000,
      max: Infinity,
      jitter: false,
    },
  },
});
const additiveLink = from([retry, onErrorLink, authLink.concat(httpLink)]);

// Use an InMemoryCache, but keep it synced to localStorage
const cache = new InMemoryCache();
const storage = window.localStorage;
const waitOnCache = persistCache({ cache, storage });
let iosPwaPopup = localStorage.getItem("iosPwaPopup");

// set refresh app state
localStorage.setItem(REFRESH_APP, false);

// setObject ...
// This function set object to localStorage/sessionStorage
Storage.prototype.setObject = function (key, obj) {
  this.setItem(key, JSON.stringify(obj));
  return obj;
};

// getObject ...
// This function get object from localStorage/sessionStorage
Storage.prototype.getObject = function (key) {
  try {
    return JSON.parse(this.getItem(key));
  } catch (e) {
    return this.getItem(key);
  }
};

// Set Popup triger for PWA IOS
if (localStorage.getItem("iosPwaPrompt")) {
  let pwaObject = JSON.parse(localStorage.getItem("iosPwaPrompt"));
  if (pwaObject.visits === 0 && isMobileSafari && isIOS) {
    localStorage.setItem("iosPwaPopup", true);
  } else {
    localStorage.setItem("iosPwaPopup", false);
  }
}

// Post message to service worker to skip waiting
const config = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      localStorage.setItem(REFRESH_APP_POPUP, true);
      registration.waiting.postMessage({
        type: "SKIP_WAITING",
      });
    }
  },
};

// Create Apollo link that contain both HTTP and WS
// const link = split(
//   // Split based on operation type
//   ({query}) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   wsLink,
//   authLink.concat(httpLink),
// );

// Create Apollo client that will be used to send GrqphQL requests
const client = new ApolloClient({
  link: additiveLink,
  cache: cache,
  queryDeduplication: false,
  defaultOptions: {
    query: {
      errorPolicy: "all",
      fetchPolicy: "network-only",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

waitOnCache.then(() => {
  root.render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        {/*   <Suspense fallback={<Loader />}> */}
        <I18nextProvider i18n={i18n}>
          <ErrorBoundary>
            <App />
            <PWAPrompt permanentlyHideOnDismiss={iosPwaPopup} />
          </ErrorBoundary>
        </I18nextProvider>
        {/*  </Suspense> */}
      </ApolloProvider>
    </BrowserRouter>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(config);
