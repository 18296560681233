import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { addYears } from "date-fns";
import Input from "common/src/component/input";
import Select from "common/src/component/select";
import { PaymentServer } from "common/src/helpers/connectors";
import {SEID, SERVER_SETUP} from "common/src/helpers/constants";
// import { isMobileOnly } from "react-device-detect";
import { toast } from "react-toastify";
import {
  OffsetMd4,
  RegisterNlStepWrapper,
  ResetButtonWrapper,
} from "../styledComponents/StyledSignupLogin";
import {
  InvalidFeedback,
  StyledDatePicker,
  StyledFormRow,
} from "../styledComponents/AccountPage";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import { PageheadlinePar } from "../styledComponents/StyledDepositAndWithdrawal";

const RegisterStep2SE = ({
  errors,
  touched,
  formData,
  navigation,
  handleChange,
  handleBlur,
  handlePhoneChange,
  handleOnPhoneBlur,
  handleOnDateBlur,
  handleDateChange,
  setTouchedOnRest,
  setSpinner,
  skin,
}) => {
  const {
    gender,
    currency,
    // title,
    firstName,
    //middleName,
    lastName,
    address,
    city,
    zipCode,
    dateOfBirth,
    phone,
    country,
    // birthPlace,
    // inserts,
    // buildingNumber,
    password,
    confirmPassword,
    email,
    // bankIBAN,
    // bankAccountHolder,
    identID
  } = formData;

  // formData.country = "nl";
  // const buttonNext = useRef(null);
  const { go, pause } = navigation;
  const { t, i18n } = useTranslation("");
  // const language = i18n.language;
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [resultPlayerData, setResultPlayerData] = useState({});
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [currentDate, SetCurrentDate] = useState(addYears(new Date(), -18));
  //let isValid = false;
  // const isUK = country === 'gb' ? true : false;
  // const nextStep = isUK ? 'stepUK' : 'stepGDPR';
  const prevStep = "stepIdentLookup";
  const nextStep = "stepGDPR";

  const currentLanguage = i18n.language || window.localStorage.i18nextLng;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getPlayer = async () => {
      await PaymentServer.post(
        /*'http://localhost:9001'+*/ "/fhnd/v1/zignsec/fetch",
        {
          ip: serverSetup.geoIP.ipAddress,
        }
      )
        .then((res) => {
          // console.log('PLAYER: ' + JSON.stringify(res.data.player));

          // res.data.player.country = "mt";

          // res.data.player.Email = "";
          // res.data.player.CountryCode = "";
          // res.data.player.LastName = "";
          // res.data.player.FirstName = "";
          // res.data.player.Address = "";
          // res.data.player.City = "";
          // res.data.player.PostalCode = "";
          // res.data.player.Phone = "";
          // res.data.player.Gender = "";
          // res.data.player.Currency = "";
          // res.data.player.Password = "";
          // res.data.player.ConfirmPassword = "";
          // res.data.player.DateOfBirth = "";
          // res.data.player.PersonalNumber = "";

          // res.data.player = JSON.parse('{"legalLastName":"Bles","preferredLastName":"Bles","partnerLastName":"","legalLastNamePrefix":"","preferredLastNamePrefix":"","partnerLastNamePrefix":"","initials":"CJB","street":"60, Olympus, Vjal Ragusa","houseNumber":"","postalCode":"","city":"MST2309  MOSTA","country":"MT","dob":"1992-01-10","gender":"male","telephone":"+35699350504","email":"carlobles92@gmail.com"}');
          // res.data.player.email = "spritzer.crassus456@gmail.com";
          // console.log('PLAYER: ' + JSON.stringify(res.data.player));
          formData.email = res.data.player.Email;
          formData.country = res.data.player.CountryCode.toLowerCase();
          formData.lastName = res.data.player.LastName;
          formData.firstName = res.data.player.FirstName;
          formData.address = res.data.player.Address;
          formData.city = res.data.player.City;
          formData.zipCode = res.data.player.PostalCode;
          formData.phone = res.data.player.Phone;
          formData.gender = res.data.player.Gender;
          formData.currency = res.data.player.Currency;
          formData.password = res.data.player.Password;
          formData.confirmPassword = res.data.player.ConfirmPassword;
          formData.dateOfBirth = res.data.player.DateOfBirth;
          formData.identID = res.data.player.PersonalNumber;

          //check if we should allow empty country?
          // if (res.data.player.country.toLowerCase() !== 'nl') {
          //   //only nl allowed
          //   toast.error(t('err_wrong_country', 'err_wrong_country'));
          //   go(prevStep);
          // }

          setResultPlayerData(res.data.player);
          localStorage.setItem(SEID, res.data.player.Password);
          return true;
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response !== "undefined" && error.response) {
            toast.error(t("err_not_allowed", "err_not_allowed"));
            go(prevStep);
          }
          return false;
        });
    };

    setSpinner(true);
    getPlayer()
      .then(() => {
        setSpinner(false);
      })
      .catch(() => {
        setSpinner(false);
      });
  }, []);

  const paramsForCheck = [
    "firstName",
    "lastName",
    "address",
    "city",
    "zipCode",
    "gender",
    "dateOfBirth",
    "phone",
    "country",
    // 'birthPlace',
    "currency",
    "password",
    "confirmPassword",
    "email",
    "identID",
  ];
  // console.log("FORM DATA: " + JSON.stringify(formData));

  // console.log("ERRORS: " + JSON.stringify(errors));
  /* on next check mandatory fields */
  const checkIsValid = () => {
    if (!currentDate) errors.dateOfBirth = "err_empty_date_of_birth";

    setTouchedOnRest(paramsForCheck);
    // console.log(errors);
    let hasErrors = 0;
    errors &&
      paramsForCheck.forEach((p) => {
        for (let key of Object.keys(errors)) {
          if (p === key) {
            hasErrors++;
          }
        }
      });
    if (hasErrors === 0) {
      return true;
    } else {
      phone.length === 0 ? setPhoneIsValid(true) : setPhoneIsValid(false);
      return false;
    }
  };

  let genderOptions = [
    { value: "", name: "" },
    { value: "male", name: "label_male" },
    { value: "female", name: "label_female" },
  ];
  // if (ibanError !== "" && !ibanErrShown) {
  //   // console.log("set error -------");
  //   errors.bankIBAN = ibanError;
  //   setIbanErrShown(true);x
  // }

  const handleChangeDate = (date) => {
    SetCurrentDate(date);
    handleDateChange(date);
  };

  // console.log(formData)

  return (
    <OffsetMd4>
      <RegisterNlStepWrapper>
        <PageheadlinePar
          dangerouslySetInnerHTML={{ __html: t("label_register_2_step") }}
        ></PageheadlinePar>
      </RegisterNlStepWrapper>
      <StyledFormRow>
        { resultPlayerData.FirstName === "" && (
          <Input
            groupClass={
                "form-group col-md-6 mb-3 " +
                (resultPlayerData.FirstName === "" ? "" : " form-value")
            }
            name="firstName"
            label={"label_first_name"}
            placeholder={true}
            value={firstName}
            type="text"
            // onChange={handleChange}
              {...(resultPlayerData.FirstName === "" && { onChange: handleChange })}
            onBlur={handleBlur}
            errors={
              errors.firstName && touched.firstName ? errors.firstName : null
            }
              readOnly={resultPlayerData.FirstName === "" ? null : true}
            autoComplete={"given-name"}
            // ref={buttonNext}
            // autoFocus={true}
            required
          />
        )}
        { resultPlayerData.LastName === "" && (
          <Input
            groupClass={
              "form-group col-md-6 mb-3 " +
              (resultPlayerData.LastName === "" ? "" : " form-value")
            }
            name="lastName"
            label={"label_last_name"}
            placeholder={true}
            value={lastName}
            type="text"
            autoComplete={"family-name"}
            // onChange={(e) => {(resultPlayerData.legalLastName === "") ? handleChange(e) : null}}
            {...(resultPlayerData.LastName === "" && {
              onChange: handleChange,
            })}
            onBlur={handleBlur}
            errors={errors.lastName && touched.lastName ? errors.lastName : null}
            readOnly={resultPlayerData.LastName === "" ? null : true}
            required
          />
        )}
      </StyledFormRow>

      <StyledFormRow>
        { resultPlayerData.Gender === "" && (
          <Select
            groupClass={
              `form-group col-md-6 mb-3 ` +
              (resultPlayerData.Gender === "" ? "" : " form-value")
            }
            name="gender"
            list={genderOptions}
            // onChange={resultPlayerData.gender === "" ? handleChange() : null}
            // onChange={(e) => {resultPlayerData.gender === "" ? handleChange(e) : null}}
            {...(resultPlayerData.Gender === "" && { onChange: handleChange })}
            onBlur={handleBlur}
            defaultValue={gender}
            firstOption="label_select_gender"
            errors={errors.gender && touched.gender ? errors.gender : null}
            readOnly={resultPlayerData.Gender === "" ? null : true}
            disabled={resultPlayerData.Gender === "" ? null : true}
            required
          />
        )}
        { resultPlayerData.DateOfBirth === "" && (
        <div
          className={
            "form-group col-md-6  register-nl mb-3 " +
            (resultPlayerData.DateOfBirth === "" ? "" : " form-value")
          }
        >
          <StyledDatePicker
            maxDate={addYears(new Date(), -18)}
            minDate={addYears(new Date(), -100)}
            value={dateOfBirth === "" ? currentDate : new Date(dateOfBirth)}
            onChange={(date) => handleChangeDate(date)}
            clearIcon={null}
            locale={currentLanguage}
            required={true}
            dayPlaceholder={"dd"}
            monthPlaceholder={"mm"}
            yearPlaceholder={"yyyy"}
          />
          {errors.dateOfBirth && touched.dateOfBirth && (
            <InvalidFeedback
              reg
              dangerouslySetInnerHTML={{ __html: t(errors.dateOfBirth) }}
            ></InvalidFeedback>
          )}
        </div>
        )}
      </StyledFormRow>
      {/*<StyledFormRow>*/}
      {/*<Input*/}
      {/*  groupClass="form-group col-md-6 mb-3"*/}
      {/*  name="birthPlace"*/}
      {/*  label={'label_birthplace'}*/}
      {/*  placeholder={true}*/}
      {/*  value={birthPlace}*/}
      {/*  type="text"*/}
      {/*  onChange={handleChange}*/}
      {/*  onBlur={handleBlur}*/}
      {/*  errors={errors.birthPlace && touched.birthPlace ? errors.birthPlace : null}*/}
      {/*/>*/}
      {/*</StyledFormRow>*/}
      { resultPlayerData.Address === "" && (
        <StyledFormRow>
          <Input
            groupClass={
              "form-group col-md-12 mb-3" +
              (resultPlayerData.Address === "" ? "" : " form-value")
            }
            name="address"
            label={"label_address"}
            placeholder={true}
            value={address}
            type="text"
            autoComplete={"street-address"}
            // onChange={resultPlayerData.street === "" ? handleChange() : null}
            // onChange={(e) => {resultPlayerData.street === "" ? handleChange(e) : null}}
            {...(resultPlayerData.Address === "" && { onChange: handleChange })}
            onBlur={handleBlur}
            errors={errors.address && touched.address ? errors.address : null}
            readOnly={resultPlayerData.Address === "" ? null : true}
          />
        </StyledFormRow>
      )}
      <StyledFormRow>
        { resultPlayerData.City === "" && (
          <Input
            groupClass={
              "form-group col-md-6 mb-3" +
              (resultPlayerData.City === "" ? "" : " form-value")
            }
            name="city"
            label={"label_city"}
            placeholder={true}
            value={city}
            type="text"
            // onChange={(e) => {resultPlayerData.city === "" ? handleChange(e) : null}}
            {...(resultPlayerData.City === "" && { onChange: handleChange })}
            onBlur={handleBlur}
            errors={errors.city && touched.city ? errors.city : null}
            readOnly={resultPlayerData.City === "" ? null : true}
            autoComplete={"home city"}
          />
        )}
        { resultPlayerData.PostalCode === "" && (
          <Input
            groupClass={
              "form-group col-md-6 mb-3" +
              (resultPlayerData.PostalCode === "" ? "" : " form-value")
            }
            name="zipCode"
            label={"label_post_code"}
            placeholder={true}
            value={zipCode}
            type="text"
            // onChange={resultPlayerData.postalCode === "" ? handleChange() : null}
            // onChange={(e) => {resultPlayerData.postalCode === "" ? handleChange(e) : null}}
            {...(resultPlayerData.PostalCode === "" && {
              onChange: handleChange,
            })}
            onBlur={handleBlur}
            errors={errors.zipCode && touched.zipCode ? errors.zipCode : null}
            readOnly={resultPlayerData.PostalCode === "" ? null : true}
            autoComplete={"postal-code"}
          />
        )}
      </StyledFormRow>
      <StyledFormRow>
        <Input
          groupClass={"form-group col-md-12 mb-3"}
          name="email"
          label={"label_email"}
          placeholder={true}
          value={email}
          type="text"
          // onChange={(e) => {resultPlayerData.email === "" ? handleChange(e) : null}}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.email && touched.email ? errors.email : null}
          readOnly={resultPlayerData.Email === "" ? null : true}
          autoFocus={true}
          required
          autoComplete={"email"}
        />
      </StyledFormRow>

      { resultPlayerData.Phone === "" && (
        <div
          className={
            "form-row" + (resultPlayerData.Phone === "" ? "" : " form-value")
          }
        >
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
              // autoFocus: true,
            }}
            autoFormat={false}
            country={resultPlayerData.Phone === "" ? country : null}
            value={phone}
            containerClass="col-md-12"
            inputClass={errors.phone && touched.phone ? "is-invalid" : ""}
            // onChange={(value, country, e, formattedValue) =>
            //   handlePhoneChange(value, country, e, formattedValue)
            // }
            // onChange={(value, country, e, formattedValue) => {
            //   resultPlayerData.telephone === "" ? handlePhoneChange(value, country, e, formattedValue) : null
            // }}
            {...(resultPlayerData.Phone === "" && {
              onChange: (value, country, e, formattedValue) =>
                handlePhoneChange(value, country, e, formattedValue),
            })}
            onBlur={(value, country, e, formattedValue) =>
              handleOnPhoneBlur(value, country, e, formattedValue)
            }
            disabled={resultPlayerData.Phone === "" ? null : true}
            autoComplete={"tel"}
          />
          {phoneIsValid ? (
            <InvalidFeedback
              reg
              tel
              dangerouslySetInnerHTML={{
                __html: t("err_empty_phone"),
              }}
            ></InvalidFeedback>
          ) : (
            errors.phone &&
            touched.phone && (
              <InvalidFeedback
                reg
                tel
                dangerouslySetInnerHTML={{
                  __html: t(errors.phone ? errors.phone : "err_empty_phone"),
                }}
              ></InvalidFeedback>
            )
          )}
        </div>
      )}

      <ResetButtonWrapper>
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="btn btn-first col-md-4"*/}
        {/*  onClick={() => go(prevStep)}*/}
        {/*  dangerouslySetInnerHTML={{ __html: t('label_go_back', 'Previous') }}*/}
        {/*></button>*/}
        <StyledButton
          btn
          type="button"
          onClick={() => (checkIsValid() ? go(nextStep) : pause())}
          dangerouslySetInnerHTML={{ __html: t("label_next", "Next") }}
        ></StyledButton>
      </ResetButtonWrapper>
    </OffsetMd4>
  );
};

export default RegisterStep2SE;
