import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentServer } from "common/src/helpers/connectors";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { isMobile } from "react-device-detect";

const ZignsecRedirect = ({ match, setSpinner }) => {
  const { i18n, t } = useTranslation("");
  const {
    params: { sessionId, sessionStatus, lookup },
  } = match;
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const language = i18n.language;
  // console.log(relayState, sessionId, sessionStatus)

  useEffect(() => {
    // console.log(trxid + " : " + ec);
    const getStatus = async () => {
      await PaymentServer.post(
        /*'http://localhost:9001'+*/ "/fhnd/v1/zignsec/session",
        {
            ip: serverSetup.geoIP.ipAddress,
            sessionId: sessionId,
            isMobile: isMobile,
            lookup: lookup === "lookup"
        }
      )
        .then((res) => {
          // console.log("STATUS: " + JSON.stringify(res.data));
          // if (isMobile) {
          //   window.opener.postMessage(
          //     JSON.stringify({
          //       error: false,
          //       message: res.data,
          //       step: 3
          //     }),
          //     window.location.origin
          //   );
          // } else {
            window.top && window.top.postMessage(
              JSON.stringify({
                error: false,
                message: res.data,
                step: 3
              }),
              window.location.origin
            );
          // }

          return true;
        })
        .catch(() => {
          // console.log("STATUS ERROR!" + error);
          // let errMessage = t(err.response.data[0]);
          // if (typeof err.response.data[1] !== "undefined") {
          //   if (err.response.data[1] !== "") {
          //     errMessage = errMessage + ": " + err.response.data[1];
          //   }
          // }
          //   if (isMobile) {
          //       window.opener.postMessage(
          //           JSON.stringify({
          //               error: false,
          //               message: {status: "error", message: "label_zignsec_error", ltoken: ""},
          //               step: 0
          //           }),
          //           window.location.origin
          //       );
          //   } else {
            window.top && window.top.postMessage(
                    JSON.stringify({
                        error: false,
                        message: {status: "error", message: "label_zignsec_error", ltoken: ""},
                        step: 0
                    }),
                    window.location.origin
                );
            // }
          return false;
        });
    };

    setSpinner(true);
    if(sessionStatus !== "Cancelled") {
        getStatus()
            .then(() => {
                // setSpinner(false);
                // console.log("GET STATUS THEN" + value);
            })
            .catch(() => {
                // console.log("GET STATUS ERROR");
                // setSpinner(false);
            });
    } else {
        // if (isMobile) {
        //     window.opener.postMessage(
        //         JSON.stringify({
        //             error: false,
        //             message: {status: "error", message: "label_cancelled", ltoken: ""},
        //             step: 0
        //         }),
        //         window.location.origin
        //     );
        // } else {
        window.top && window.top.postMessage(
                JSON.stringify({
                    error: false,
                    message: {status: "error", message: "label_cancelled", ltoken: ""},
                    step: 0
                }),
                window.location.origin
            );
        // }
    }
  }, [language, serverSetup.geoIP.ipAddress, setSpinner, t, sessionId]);

  return (
    <>
      <div className="idin-redirect message text-center mt-5" style={{position: "absolute", color: "white", bottom: "5vh", margin: "auto", width: "100%", left: 0, right: 0}}>
        {t("label_please_wait", "label_please_wait")}
      </div>
    </>
  );
};

export default ZignsecRedirect;
